<template>
  <div id="app">
    <MatrixBackground />
    <TesseractContainer/>
    <table />
  </div>
</template>

<script>
import TesseractContainer from './components/tesseractContainer.vue';
import MatrixBackground from './components/MatrixBackground.vue';

export default {
  components: {
    MatrixBackground,
    TesseractContainer,
  },
};
</script>

