<template>
  <canvas id="matrix" ref="matrixCanvas"></canvas>
</template>

<script>
export default {
  data() {
    return {
      ctx: null,
      drops: [],
      font_size: 10,  // Larger font size
      speed: 2,     // Slightly increased speed
      color: '#00FF00',  // Brighter green color
      trailColor: 'rgba(0, 0, 0, 0.1)',  // Darker trail for more contrast
      intervalId: null,  // Store interval ID to clear it later
    };
  },
  mounted() {
    this.initializeMatrix();
    window.addEventListener('resize', this.resizeCanvas);

    // Set up interval to call drawMatrix every 66ms (roughly 15 frames per second)
    this.intervalId = setInterval(this.drawMatrix, 66);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeCanvas);

    // Clear the interval to stop the animation
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    initializeMatrix() {
      const canvas = this.$refs.matrixCanvas;
      this.ctx = canvas.getContext('2d');
      this.resizeCanvas(); // Adjust canvas size on load
      this.drops = Array(Math.floor(canvas.width / this.font_size)).fill(0); // Initialize drops
    },
    resizeCanvas() {
      const canvas = this.$refs.matrixCanvas;
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      this.drops = Array(Math.floor(canvas.width / this.font_size)).fill(0); // Reset drops on resize
    },
    drawMatrix() {
      const canvas = this.$refs.matrixCanvas;

      // Create a trail effect by clearing the canvas with a semi-transparent fill
      this.ctx.fillStyle = this.trailColor;
      this.ctx.fillRect(0, 0, canvas.width, canvas.height);

      this.ctx.fillStyle = this.color; // Set the text color to bright green
      this.ctx.font = `${this.font_size}px monospace`;

      // Draw the matrix effect
      this.drops.forEach((y, index) => {
        const text = 'アカサタナハマヤラワ0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ@#$%^&*()*&^%// 𒀀𒀁𒀂𒀃𒀖𒀗𒀘𒀙𒀬𒂄𒂅𒂆𒂚𒂛𒂜𒂝𒂱𒂲𒂳𒂴𒂵𒃉𒃪𒃫𒃿𒄀𒄁𒄂𒄃𒄄𒄅𒄆𒄚𒄛𒄜𒄝𒄞𒄟𒄳𒄴𒄵𒄶𒄷𒄸𒄹𒄺𒅎𒅏𒅐𒅑𒅥𒅦𒅧𒅨𒅼𒅽𒅾𒅿𒆓𒆔𒆕𒆖𒆪𒆫𒆬𒆭𒆮𒆯𒆰𒆱𒆲𒇆𒇇𒇈𒇉𒇊𒇋𒇌𒇍𒇎𒇏𒇐𒇑𒇒𒇦𒇧𒇨𒇩𒇪𒇫𒇬𒇭𒇮𒇯𒇰𒇱𒇲𒇳𒇴𒇵𒈉𒈊𒈋𒈌𒈍𒈎𒈏𒈐𒈑𒈒𒈦𒈧𒈨𒈩𒈽𒈾𒈿𒉀𒉁𒉕𒉖𒉗𒉘𒉙𒉚𒉛𒉜𒉝𒉞𒉟𒉳𒉴𒉵𒉶𒉷𒉸𒉹𒉺𒉻𒊏𒊐𒊑𒊒𒊓𒊔𒊕𒊖𒊗𒊫𒊬𒊭𒊮𒊯𒊰𒊱𒊲𒊳𒊴𒊵𒊶𒊷𒊸𒊹𒊺𒋎𒋏𒋐𒋑𒋒𒋓𒋔𒋕𒋩𒋪𒋫𒋬𒋭𒋮𒋯𒋰𒋱𒋲𒋳𒋴𒋵𒋶𒌊𒌋𒌌𒌍𒌎𒌏𒌐𒌑𒌒𒌓𒌔𒌕𒌖𒌪𒌫𒌬𒌭𒌮𒍂𒍃𒍄𒍅𒍆𒍇𒍈𒍉𒍊𒍋𒍌𒍍𒍡𒍢𒍣𒍤𒍥𒍦𒍧𒍨𒍩𒍪𒍫𒍾𒍿𒎀𒎁𒎕𒎖𒎗𒎘𒎙'.charAt(
          Math.floor(Math.random() * 70)
        );
        const x = index * this.font_size;
        this.ctx.fillText(text, x, y * this.font_size);

        // Randomly reset the drop to the top of the canvas
        if (y * this.font_size > canvas.height && Math.random() > 0.975) {
          this.drops[index] = 0;
        }

        // Increase the y position based on speed
        this.drops[index] += this.speed;  // Slower speed
      });
    },
  },
};
</script>

<style scoped>
canvas {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  pointer-events: none;
}
</style>
