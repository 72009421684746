<template>
  <div id="tesseract-container" ref="tesseractContainer"></div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ref, onMounted, onUnmounted } from 'vue';

export default {
  setup() {
    const tesseractContainer = ref(null); // The container for Three.js canvas
    let scene, camera, renderer, tesseractGroup, controls; // Declare non-reactive Three.js objects
    let wireframeMaterial, faceMaterial, solidFaceMaterial; // Materials for hover effects

    const autoRotateSpeed = 0.002;  // Rotation speed

    // Initialize Three.js Scene
    const initThree = () => {
      // Create the scene
      scene = new THREE.Scene();

      // Create the camera
      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.z = 5;

      // Create the renderer
      renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      tesseractContainer.value.appendChild(renderer.domElement);

      // Orbit controls for user interaction
      controls = new OrbitControls(camera, renderer.domElement);

      // Initialize materials
      wireframeMaterial = new THREE.LineBasicMaterial({ color: 0x00ff00 }); // Default wireframe material
      faceMaterial = new THREE.MeshBasicMaterial({
        color: 0xffffff,
        transparent: true,
        opacity: 0.3,  // Default transparency
        side: THREE.DoubleSide,
      });
      solidFaceMaterial = new THREE.MeshBasicMaterial({
        color: 0x0000ff,
        opacity: 0.8, // Less transparent on hover
        transparent: true,
        side: THREE.DoubleSide,
      });

      // Create the tesseract group
      createTesseract();

      // Start the animation loop
      animate();
    };

    // Function to create the Tesseract
    const createTesseract = () => {
      tesseractGroup = new THREE.Group(); // Group to hold tesseract edges and faces

      const vertices = [];
      const tesseractVertices = [
        [-1, -1, -1, -1], [1, -1, -1, -1], [-1, 1, -1, -1], [1, 1, -1, -1],
        [-1, -1, 1, -1], [1, -1, 1, -1], [-1, 1, 1, -1], [1, 1, 1, -1],
        [-1, -1, -1, 1], [1, -1, -1, 1], [-1, 1, -1, 1], [1, 1, -1, 1],
        [-1, -1, 1, 1], [1, -1, 1, 1], [-1, 1, 1, 1], [1, 1, 1, 1]
      ];

      // Project from 4D to 3D and store vertices as flat array
      tesseractVertices.forEach(([x, y, z, w]) => {
        const distance = 3;
        const factor = distance / (distance - w);
        vertices.push(x * factor, y * factor, z * factor); // Push flat x, y, z coordinates
      });

      const tesseractEdges = [
        [0, 1], [0, 2], [0, 4], [0, 8], [1, 3], [1, 5], [1, 9], [2, 3],
        [2, 6], [2, 10], [3, 7], [3, 11], [4, 5], [4, 6], [4, 12], [5, 7],
        [5, 13], [6, 7], [6, 14], [7, 15], [8, 9], [8, 10], [8, 12], [9, 11],
        [9, 13], [10, 11], [10, 14], [11, 15], [12, 13], [12, 14], [13, 15],
        [14, 15]
      ];

      // Create edges
      tesseractEdges.forEach(([start, end]) => {
        const geometry = new THREE.BufferGeometry();
        const edgeVertices = new Float32Array([
          vertices[start * 3], vertices[start * 3 + 1], vertices[start * 3 + 2], // Start vertex
          vertices[end * 3], vertices[end * 3 + 1], vertices[end * 3 + 2]  // End vertex
        ]);
        geometry.setAttribute('position', new THREE.BufferAttribute(edgeVertices, 3));
        const line = new THREE.Line(geometry, wireframeMaterial);
        tesseractGroup.add(line);
      });

      // Create faces for the tesseract (each face is a quad made from two triangles)
      const tesseractFaces = [
        [0, 1, 3, 2], [4, 5, 7, 6], [0, 1, 5, 4], [2, 3, 7, 6],
        [0, 2, 6, 4], [1, 3, 7, 5], [8, 9, 11, 10], [12, 13, 15, 14],
        [8, 9, 13, 12], [10, 11, 15, 14], [8, 10, 14, 12], [9, 11, 15, 13]
      ];

      tesseractFaces.forEach(face => {
        const faceGeometry = new THREE.BufferGeometry();
        const verticesArray = [];
        face.forEach(vIndex => {
          verticesArray.push(vertices[vIndex * 3], vertices[vIndex * 3 + 1], vertices[vIndex * 3 + 2]);
        });
        faceGeometry.setAttribute('position', new THREE.Float32BufferAttribute(verticesArray, 3));
        faceGeometry.setIndex([0, 1, 2, 0, 2, 3]);  // Two triangles per face

        const mesh = new THREE.Mesh(faceGeometry, faceMaterial); // Start with transparent face
        tesseractGroup.add(mesh);
      });

      scene.add(tesseractGroup);
    };

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      // Rotate the tesseract
      tesseractGroup.rotation.x += autoRotateSpeed;
      tesseractGroup.rotation.y += autoRotateSpeed;

      controls.update();  // Update the controls
      renderer.render(scene, camera);  // Render the scene
    };

    // Handle hover enter (change material)
    const onHoverEnter = () => {
      tesseractGroup.children.forEach(child => {
        if (child.isMesh) {
          child.material = solidFaceMaterial; // Change to solid blue on hover
        }
      });
    };

    // Handle hover leave (reset material)
    const onHoverLeave = () => {
      tesseractGroup.children.forEach(child => {
        if (child.isMesh) {
          child.material = faceMaterial; // Change back to transparent
        }
      });
    };

    // Handle window resizing
    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    // Lifecycle hooks
    onMounted(() => {
      initThree();
      window.addEventListener('resize', onWindowResize);

      // Add hover event listeners
      const container = tesseractContainer.value;
      container.addEventListener('mouseenter', onHoverEnter);
      container.addEventListener('mouseleave', onHoverLeave);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', onWindowResize);

      // Remove hover event listeners
      const container = tesseractContainer.value;
      container.removeEventListener('mouseenter', onHoverEnter);
      container.removeEventListener('mouseleave', onHoverLeave);
    });

    return { tesseractContainer };
  }
};
</script>

<style scoped>
#tesseract-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* Full height for the 3D visualization */
  z-index: 1;  /* Ensure it's rendered on top */
  cursor: pointer; /* Change the cursor when hovering */
}
</style>